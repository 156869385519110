<template>
  <div>
    <KTCodePreview v-bind:title="'Danh sách phiếu nhập hàng'">
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <div class="search-col">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs.start"
                v-model="dpForm.startDate"
              ></date-picker>
              <span class="ml-1 mr-1"></span>
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs.end"
                v-model="dpForm.endDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col>
            <Autosuggest
              class="border-radius-none"
              :model="searchStore"
              :suggestions="filteredOptionStore"
              placeholder="kho"
              :limit="200"
              @select="onSelectedStore"
              @change="onInputChangeStore"
              suggestionName="suggestionName"
              :disabled="disableStore"
            />
          </b-col>
          <b-col>
            <Autosuggest
              class="border-radius-none"
              :model="searchProvider"
              :suggestions="filteredOptionsProvider"
              placeholder="nhà cung cấp"
              :limit="200"
              @select="onSelectedProvider"
              @change="onInputChangeProvider"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="selectedImportStatus"
              :options="listImportStatus"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="3">
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col md="3">
            <b-form-input
              v-model="searchDescription"
              type="text"
              placeholder="Tìm kiếm nội dung"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
            >Lọc</b-button>
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listItem"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table
              :speed="5"
              :animate="true"
              :columns="10"
            ></vcl-table>
          </template>

          <template v-slot:cell(code)="row">
            <div style="
                justify-content: center;
                align-items: center;
                display: grid;
              ">
              <a
                class="font-weight-bold"
                @click="onShowPOInfoModal(row.item.code)"
                style="cursor: pointer"
              >
                {{ row.item.code }}
              </a>
              <br />
              <p>{{ row.item.createdAt }}</p>
              <p>{{ row.item.createdByName }}</p>
            </div>
          </template>
          <template v-slot:cell(providerName)="row">
            <span v-text="row.item.providerName"></span>
          </template>
          <template v-slot:cell(storeName)="row">
            <div class="font-weight-bolder">
              <span v-text="row.item.storeName"></span>
            </div>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown
                size="sm"
                id="dropdown-left"
                no-caret
                right
              >
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="printItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-printer"
                    ></i>
                    &nbsp; In phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="onShowPOInfoModal(row.item.code)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon-eye"
                    ></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="addStockSlip(row.item)"
                  v-if="!row.item.totalImportQuantity"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-add-1"
                    ></i>
                    &nbsp; Tạo phiếu nhập
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="addExportStockSlip(row.item)"
                  v-if="row.item.totalImportQuantity"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="fas fa-file-export"
                    ></i>
                    &nbsp; Tạo phiếu xuất trả
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
        <POInfo ref="purchase-order-info-modal" />
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import POInfo from '../../components/purchase-orders/POInfo';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';

import moment from 'moment';
import ApiService from '@/core/services/api.service';
import { orderBy } from 'lodash';
import { PO_RELATION_TYPE, PURCHASE_ORDER_STATUS } from '../../../utils/enum';
import { convertPrice } from '../../../utils/common';

export default {
  data() {
    return {
      dpForm: {
        startDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      listItem: [],
      searchId: '',
      searchDescription: '',
      totalItems: 0,
      totalPages: 1,
      page: 1,
      searchFromDay: '',
      searchToDay: '',
      fields: [
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'storeName',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'providerName',
          label: 'NCC',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'totalProductCount',
          label: 'SL SP',
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'totalProductQuantity',
          label: 'Tổng SL',
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'totalImportQuantity',
          label: 'SL đã nhập',
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'description',
          label: 'Nội dung',
          thStyle: { fontWeight: 600, color: '#181c32', width: '26%' },
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      searchProvider: '',
      selectedProvider: null,
      optionsProvider: [],
      filteredOptionsProvider: [],
      searchStore: 'Chọn kho',
      selectedStoreId: null,
      optionsStore: [],
      filteredOptionStore: [],
      selectedImportStatus: null,
      listImportStatus: [
        {
          id: null,
          name: 'Trạng thái nhập kho',
        },
        {
          id: 1,
          name: 'Nhập đủ',
        },
        {
          id: 2,
          name: 'Nhập thiếu',
        },
        {
          id: 3,
          name: 'Nhập dư',
        },
        {
          id: 4,
          name: 'Chưa nhập hàng',
        },
      ],
      disableStore: false,
    };
  },
  methods: {
    convertPrice,
    fetchStore: async function () {
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.optionsStore = stores.map((element) => {
          return {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
        });
        this.filteredOptionStore = [...this.optionsStore];
      });
    },
    onSelectedStore(option) {
      this.selectedStoreId = option.item.id;
      this.searchStore = option.item.name;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStore = text;

      const filteredData = this.optionsStore
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredOptionStore = [...filteredData];
    },
    onSelectedProvider(option) {
      this.selectedProvider = option.item.id;
      this.searchProvider = option.item.name;
    },
    onInputChangeProvider(text) {
      if (!text) {
        text = '';
      }
      this.searchProvider = text;

      const filteredData = this.optionsProvider
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, 10);

      this.filteredOptionsProvider = [...filteredData];
    },
    fetchProvider() {
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.optionsProvider = data.data.providers.map((element) => {
          return {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
          };
        });
        const noneProvider = {
          id: null,
          name: 'Tất cả NCC',
          suggestionName: 'Tất cả NCC',
        };
        this.optionsProvider.unshift(noneProvider);
        this.filteredOptionsProvider = [...this.optionsProvider];
      });
    },
    onShowPOInfoModal(code) {
      this.$refs['purchase-order-info-modal'].showModal(code);
    },
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      if (!this.searchProvider || this.searchProvider.trim() === '') {
        this.selectedProvider = null;
      }

      if (!this.searchStore || this.searchStore.trim() === '') {
        this.selectedStoreId = null;
      }

      const params = {
        pageNumber: this.page,
        pageSize: 10,
        description: this.search ? this.search.trim() : '',
        code: this.searchId ? this.searchId.trim() : '',
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        storeId: this.selectedStoreId,
        providerId: this.selectedProvider,
        importStatus: this.selectedImportStatus,
        relationType: [PO_RELATION_TYPE.INDEPENDENT, PO_RELATION_TYPE.CHILD],
        status: PURCHASE_ORDER_STATUS.ACCOUNTANT,
      };

      if (!params.description) delete params.description;
      if (!params.code) delete params.code;
      if (!params.fromDate) delete params.fromDate;
      if (!params.toDate) delete params.toDate;
      if (!params.storeId) delete params.storeId;
      if (!params.providerId) delete params.providerId;
      if (!params.importStatus) delete params.importStatus;

      ApiService.query('purchase-order', { params })
        .then((response) => {
          const responseData = response.data.data;
          this.totalPages = responseData.totalPage;
          this.totalItems = responseData.total;
          const listData = orderBy(responseData.data, ['id'], ['desc']);
          this.listItem = listData.map((item, index) => {
            return {
              ...item,
              count: index + 1,
              createdBy: item.createdByName ? item.createdByName : '',
              createdAt: moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss'),
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'import-purchase-orders',
      });
      this.fetchData();
    },
    addStockSlip: function (item) {
      this.$router.push({
        name: 'add-stock',
        query: { purchaseOrderId: item.id },
      });
    },
    addExportStockSlip: function (item) {
      this.$router.push({
        name: 'add-export-stock',
        query: { purchaseOrderId: item.id },
      });
    },
    printItem: function (item) {
      this.$router.push({
        name: 'print-purchase-orders',
        query: { id: item.id },
      });
    },
    fetchStoreByUser: async function () {
      await ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.selectedStoreId = stores[0].id;
            this.searchStore = stores[0].name;
            this.disableStore = true;
          } else {
            this.optionsStore = stores.map((element) => {
              return {
                id: element.id,
                name: element.name,
                shortName: element.shortName,
                suggestionName: element.shortName + ' ( ' + element.name + ')',
              };
            });
            this.filteredOptionStore = [...this.optionsStore];
          }
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu nhập hàng', route: 'purchase-orders' },
      { title: 'Danh sách phiếu yêu cầu nhập hàng' },
    ]);
  },
  async created() {
    await this.fetchStoreByUser();
    this.fetchProvider();
    this.fetchData();
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
    POInfo,
    Autosuggest,
  },
};
</script>

<style scoped>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.search-col {
  display: flex;
  align-items: center;
}
</style>
